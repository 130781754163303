<template>
    <div style="display: flex;flex-wrap: wrap;">
        <el-select v-model="query.series" clearable filterable placeholder="系列" @change="reload">
            <el-option v-for="series in this.series_list" :label="series" :value="series"></el-option>
        </el-select>
    </div>
    <el-table style="margin-top: 20px;" v-loading="loading" :data="data">
        <el-table-column label="ID" prop="id" width="200"></el-table-column>
        <el-table-column label="系列" prop="series" width="150">
          <template #default="scope">
            <el-tag :disabled="loading" v-if="scope.row.series" v-for="series in scope.row.series">{{ series }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="图片" prop="resource" width="300">
            <template #default="scope">
                <el-image lazy :src="`https://jigsaw-cdn.plutodesk.com/${scope.row.origin}`" style="width: 200px"/>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="300">
            <template #default="scope">
                <el-button :disabled="loading" type="primary" size="small" @click="openDiffDialog(scope.row)">不同点</el-button>
                <el-button :disabled="loading" :type="getButtonType(scope.row)" size="small" @click="addToLibrary(scope.row)">添加到图库</el-button>
            </template>
        </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: space-between">
        <el-pagination layout="prev, pager, next" :total="total" :page-size="this.query.limit"
                       @current-change="pageChange" :disabled="loading"></el-pagination>
        <el-select v-model="query.limit" style="width: 100px" @change="reload" :disabled="loading">
            <el-option :value="20"></el-option>
            <el-option :value="50"></el-option>
            <el-option :value="100"></el-option>
            <el-option :value="500"></el-option>
        </el-select>
    </div>
    <el-dialog v-model="dialog_diff_opened" :close="reset" width="80%">
      <el-form v-if="editing_diff.image" ref="editing_diff" label-width="30px" :model="editing_diff">
        <el-form-item>
          <div style="display: flex; justify-content: space-between; width: 100%; margin-top: 5px;">
            <div style="width: 50%; margin-top: 5px;">
              <el-image lazy :src="`https://jigsaw-cdn.plutodesk.com/${editing_diff.image.origin}`" style="width: 100%; height: auto;" @load="handleImageLoad"/>
              <div id="difference-image" style="position: relative; display: inline-block;margin-top: 5px;" @click="handleImageClick">
                <el-image lazy :src="`https://jigsaw-cdn.plutodesk.com/${editing_diff.image.origin}`"  class="image-origin"  style="width: 100%;height: auto;"/>
                <el-image lazy :src="`https://jigsaw-cdn.plutodesk.com/${editing_diff.image.differences}`" class="image-differences" style="width: 100%; height: auto;"/>
                <div v-if="editing_diff.circles" v-for="(circle, key) in editing_diff.circles" :key="key">
                  <div v-if="circle.visible" :style="{ position: 'absolute', top: (circle.y - circle.radius) + 'px', left: (circle.x - circle.radius) + 'px',
                    width: 2 * circle.radius + 'px', height: 2 * circle.radius + 'px', borderRadius: '50%', border: '3px solid red', zIndex: 100}">
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 50%; margin-top: 5px; margin-left: 5px">
              <div v-for="(region, index) in editing_diff.regions" style="display: flex; margin-bottom: 20px; align-items: center;">
                <label style="width: 90px; margin-right: 5px;margin-left: 15px; align-self: flex-start;">不同点 {{index}}：</label>
                <div style="position: relative; width: 50px;" @click="handleDiffClick(index)">
                  <el-image :src="`data:image/jpeg;base64,${region.image}`" style="width: 50px; height: auto;"/>
                  <div v-if="editing_diff.circles[index].visible" style="border: 2px solid red; position: absolute; width: 100%; height: 100%; top: 0; left: 0;"></div>
                </div>
                <el-radio-group v-model="editing_diff.regions[index].difficulty" :disabled="loading" style="margin-left: 30px; align-self: flex-start;">
                  <el-radio :label="1" style="font-weight: normal">简单</el-radio>
                  <el-radio :label="2" style="font-weight: normal">中等</el-radio>
                  <el-radio :label="3" style="font-weight: normal">困难</el-radio>
                </el-radio-group>
                <!-- <label for="switch" style="margin-right: 10px;margin-left: 20px; align-self: flex-start;">开关：</label>-->
                <el-switch
                    :model-value="editing_diff.regions[index].disabled === undefined || !editing_diff.regions[index].disabled" @update:model-value="(value) => toggleEnabled(index, value)"
                    :inactive-value="false" :active-value="true" :disabled="loading" style="margin-left: 30px; align-self: flex-start;"></el-switch>
              </div>
            </div>
          </div>
          <div style="display: flex; justify-content: left; margin-top: 5px;">
            <el-image lazy :src="`https://jigsaw-cdn.plutodesk.com/${editing_diff.image.differences}`" style="width: 50%; height: auto;"/>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" :loading="loading" text @click="reset">取消</el-button>
<!--        <el-button type="primary" :loading="loading" @click="submitDifferences">确定</el-button>-->
      </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import DateRangePicker from "../base/DateRangePicker";
import {update} from "../libs/utils";
import {ElMessage, ElMessageBox} from 'element-plus'

export default {
    name: "Index",
    components: {DateRangePicker},
    data() {
        return {
            loading: false, data: [], total: 0, page: 1, dialog_opened: false, dialog_diff_opened: false, editing: null, series_list: [],
            query: {
                id: null,
                online: null,
                status: null,
                series: null,
                // module: null,
                limit: 20,
                skip: 0,
                start: null,
                end: null,
                unauthorized: null
            },
            form: {
                origin: "",
                differences: "",
                // cost: 0,
                series: [],
                // source: null,
                // module: 'LIBRARY',
                status: null,
                online_date: null,
                include_country: [],
                exclude_country: [],
                desc: null,
                unauthorized: null
            },
            editing_diff: {
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/source/jigsaw/image`, {params: this.query}).then(res => {
                this.data = res.data.data.imageList;
                this.total = res.data.data.total;
                this.loading = false;
            });
        },
        reload() {
            if (!this.query.id || this.query.id.length === 24) {
                this.skip = 0;
                this.page = 1;
                this.init();
            }
        },
        df(map, list) {
            list.forEach(l => {
                map[l.id] = l.name;
                if (l.children.length) {
                    this.df(map, l.children);
                }
            });
        },
        pageChange(page) {
            this.query.skip = (page - 1) * this.query.limit;
            this.init();
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.form, item);
                    this.editing = item;
                }
            })
        },
        reset() {
            if(this.$refs.form){
                this.$refs.form.resetFields();
            }
            this.loading = this.dialog_opened = this.dialog_diff_opened = false;
            this.editing = null;
            this.form.include_country = [];
            this.form.exclude_country = [];
            this.form.unauthorized = null;
            this.editing_diff = {}
        },
        handleDiffClick(index){
          if(this.editing_diff.circles) {
            this.editing_diff.circles[index].visible = !this.editing_diff.circles[index].visible
          }
        },
        handleImageClick(event){
          const rect = document.getElementById('difference-image').getBoundingClientRect();
          const x = event.clientX - rect.left;
          const y = event.clientY - rect.top;
          if (this.editing_diff.circles) {
            for (const index in this.editing_diff.circles) {
              let circle = this.editing_diff.circles[index];
              let distance = (circle.x - x) * (circle.x - x) + (circle.y - y) * (circle.y - y)
              if (distance < circle.radius * circle.radius) {
                if(circle.visible){
                  continue
                }
                circle.visible = true;
                break;
              }
            }
          }
        },
        handleImageLoad(event){
          const img = event.target;
          this.editing_diff.imageWidth = img.naturalWidth
          this.editing_diff.imageHeight = img.naturalHeight
          this.editing_diff.displayWidth = img.width;
          this.editing_diff.displayHeight = img.height;
          if(this.editing_diff.regions && Object.keys(this.editing_diff.regions).length !== 0) {
            this.loadCircles()
          }
        },
        getButtonType(row) {
          if (!row.added) {
            return 'danger';
          } else {
            return 'success';
          }
        },
        openDiffDialog(item) {
          this.dialog_diff_opened = true
          this.editing_diff = {
            image: item,
            regions: {},
          }
          this.loadDifferences(item)
        },
        addToLibrary(item) {
          // this.loading = true
          item.added = true;
          axios.put(`/cms/v1/source/jigsaw/image/${item.id}`, {}).then(res => {
            // this.loading = false;
          });
        },
        loadDifferences(image) {
          this.loading = true
          axios.get(`/cms/v1/source/jigsaw/image/${image.id}/diff`).then(res => {
            this.loading = false
            if(this.editing_diff.image && image.id === this.editing_diff.image.id) {
              this.editing_diff.regions = res.data.data.diffList
              for (const index in this.editing_diff.regions) {
                let region = this.editing_diff.regions[index]
                if (!region.hasOwnProperty('difficulty')) {
                  region.difficulty = 1
                }
              }
              if (typeof this.editing_diff.imageWidth !== 'undefined' && typeof this.editing_diff.imageHeight !== 'undefined') {
                this.loadCircles()
              }
            }
          })
        },
        loadCircles(){
          this.editing_diff.circles = {}
          let ratioWidth = this.editing_diff.imageWidth/this.editing_diff.displayWidth
          let ratioHeight = this.editing_diff.imageHeight/this.editing_diff.displayHeight
          for (const index in this.editing_diff.regions) {
            let circle = {}
            let region = this.editing_diff.regions[index]
            let left = parseInt(region.x / ratioWidth)
            let top = parseInt(region.y / ratioHeight)
            let width = parseInt(region.width / ratioWidth)
            let height = parseInt(region.height / ratioHeight)
            circle.x = parseInt(left + width/2)
            circle.y = parseInt(top + height/2)
            circle.radius = Math.max(width, height)
            circle.visible = false
            this.editing_diff.circles[index] = circle
          }
        },
        toggleEnabled(index, value) {
          this.editing_diff.regions[index].disabled = !value;
        },
    },
    mounted() {
        this.loading = true;
        axios.all([
            axios.get('/cms/v1/source/jigsaw/series').then(res => {
                this.series_list = [];
                res.data.data.seriesList.forEach(s => {
                    if (s && s.key && !this.series_list.includes(s.key)) {
                        this.series_list.push(s.key);
                    }
                });
            }),
        ]).then(_ => {
          this.init();
        })
    },
}
</script>

<style scoped>

.image-origin {
  width: auto;
  height: auto;
}

.image-differences {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  z-index: 1;
}

</style>