<template>
    <el-calendar v-loading="loading" ref="calendar">
        <template #date-cell="{data}">
            <div>{{ data.day.split('-').slice(1).join('-') }}</div>
            <div style="display: flex;flex-wrap: wrap" @click="clickDaily(data_map[data.day], data)">
                <img style="margin: 3px;width: 30px" :src="`${$CDN_URL}/${i.thumbnail}`"
                     v-for="i in data_map[data.day].slice(0, 10)" v-if="data_map[data.day]">
            </div>
        </template>
    </el-calendar>
    <date-range-picker v-model="form" :disabled="loading" style="margin-top: 20px;" clearable></date-range-picker>
    <el-table v-loading="loading" :data="dataPage">
        <el-table-column label="ID" prop="id" width="130"></el-table-column>
        <el-table-column label="状态" prop="status" width="150">
            <template #default="scope">
                <el-select :disabled="loading" style="width: 100px" v-model="scope.row.status"
                           @change="update(scope.row)">
                    <el-option label="通过" value="AUDITED"></el-option>
                    <el-option label="初审中" value="AUDITING"></el-option>
                    <el-option label="审核中" value="REVIEWING"></el-option>
                    <el-option label="不通过" value="REJECTED"></el-option>
                    <el-option label="不使用" value="REFUSED"></el-option>
                </el-select>
            </template>
        </el-table-column>
        <el-table-column label="图片" prop="thumbnail" width="220">
            <template #default="scope">
                <el-image lazy :src="`${$CDN_URL}/${scope.row.thumbnail}`" style="width: 180px"/>
            </template>
        </el-table-column>
        <el-table-column label="上线时间" prop="online_date" width="180">
            <template #default="scope">
                <el-date-picker style="width: 120px" value-format="YYYY-MM-DD" v-model="scope.row.online_date"
                                @change="update(scope.row)" :clearable="false"></el-date-picker>
            </template>
        </el-table-column>
        <el-table-column label="蒙层" prop="mask" width="130">
          <template #default="scope">
            <el-select :disabled="loading" style="width: 100px" v-model="scope.row.mask"
                       @change="updateDaily(scope.row)">
              <el-option label="Yes" :value="true"></el-option>
              <el-option label="No" :value="false"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template #default="scope">
            <el-button type="primary" :disabled="scope.row.isAdded" @click="updateDaily(scope.row)">
              {{ scope.row.isAdded ? '已添加' : '添加' }}
            </el-button>
          </template>
        </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: space-between">
        <el-pagination layout="prev, pager, next" :total="dataFilter.length" :page-size="size"
                       v-model:current-page="page" :disabled="loading"></el-pagination>
    </div>

    <el-dialog v-model="dialog_opened" :before-close="reset" width="80%">
      <vue-draggable-next v-model="list" :animation="300" handle=".handle">
        <div v-for="l in list" style="margin: 5px;position: relative;display: inline-block"
             :class="'handle'">
          <el-image :src="`${$CDN_URL}/${l.thumbnail}`" style="width: 200px;cursor: pointer;"></el-image>
        </div>
      </vue-draggable-next>
      <template #footer>
        <el-button @click="reset" :loading="loading">取消</el-button>
        <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
      </template>
    </el-dialog>

</template>

<script>
import axios from "ts-axios-new";
import DateRangePicker from "../base/DateRangePicker";
import {VueDraggableNext} from "vue-draggable-next";
import {update} from "../libs/utils";

export default {
    name: "Daily",
    components: {VueDraggableNext, DateRangePicker},
    data() {
        return {
            loading: false, data: [], data_map: {}, dialog_opened: false, page: 1, size: 20,
            list: [], editing: [],
            form: {
                start: null,
                end: null,
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/daily`).then(res => {
                this.data = res.data.data.imageList;
                this.reload();
                this.loading = false;
            })
        },
        reload() {
            this.data_map = {};
            this.data.forEach(image => {
                if (image.online_date && image.status === 'AUDITED') {
                    if (this.data_map[image.online_date]) {
                        this.data_map[image.online_date].push(image);
                    } else {
                        this.data_map[image.online_date] = [image];
                    }
                }
            })
        },
        update(row) {
          this.loading = true;
          axios.put(`/cms/v1/image/${row.id}`, row).then(_ => {
            this.loading = false;
            this.reload()
          })
        },
        updateDaily(row) {
          axios.post(`/cms/v1/daily`, {date: row.online_date, image_id: row.id, mask: row.mask}).then(_ => {
            row.isAdded = true
            this.reload();
          })
        },
        clickDaily(selectedList, date) {
          if (selectedList) {
            this.dialog_opened = true;
            this.editing = selectedList;
            this.form.end = this.form.start = date.day
            update(this.list, selectedList);
          }
        },
        df(map, list) {
            list.forEach(l => {
                map[l.id] = l.name;
                if (l.children.length) {
                    this.df(map, l.children);
                }
            });
        },
        reset() {
          this.dialog_opened = this.loading = false;
          this.list = this.editing = []
        },
        submit() {
          this.loading = true;
          const sort = [];
          this.list.forEach((d, i) => {
            sort.push(d.id)
            d.sort = i
          });
          axios.post(`/cms/v1/sort/image`, {sort}).then(_ => {
            update(this.editing, this.list);
            this.init()
            this.reset();
          });
        },
    },
    mounted() {
        this.loading = true;
        this.init();
    },
    computed: {
        dataFilter() {
            return this.data.filter(d => {
                return !this.form.start && !this.form.end || this.form.start <= d.online_date && this.form.end >= d.online_date;
            })
        },
        dataPage() {
            return this.dataFilter.slice((this.page - 1) * this.size, this.page * this.size);
        },
    }
}
</script>

<style scoped>
.el-calendar ::v-deep(.el-calendar-day) {
    height: 100px;
}
</style>