<template>
    <el-calendar ref="calendar" v-loading="loading">
        <template #date-cell="{data}">
            <div>{{ data.day.split('-').slice(1).join('-') }}</div>
            <div style="display: flex;flex-wrap: wrap" @click="openDialog(data_map[data.day])">
                <img style="margin: 3px;width: 20px" :src="`${$CDN_URL}/${i.thumbnail}`"
                     v-for="i in data_map[data.day].slice(0, 10)" v-if="data_map[data.day]">
            </div>
        </template>
    </el-calendar>
    <el-table style="margin-top: 20px;" v-loading="loading" :data="dataPage">
        <el-table-column label="ID" prop="id" width="130"></el-table-column>
        <el-table-column label="状态" prop="status" width="100">
            <template #default="scope">
                <el-select :disabled="loading" style="width: 80px" v-model="scope.row.status"
                           @change="update(scope.row)">
                    <el-option label="通过" value="AUDITED"></el-option>
                    <el-option label="审核中" value="AUDITING"></el-option>
                    <el-option label="不通过" value="REJECTED"></el-option>
                </el-select>
            </template>
        </el-table-column>
        <el-table-column label="图片" prop="resource" width="200">
            <template #default="scope">
                <el-image lazy :src="`${$CDN_URL}/${scope.row.thumbnail}`" style="width: 180px"/>
                <div style="text-align: center;">
                    <a :href="`${$CDN_URL}/${scope.row.resource}`" target="_blank">高清大图</a>
                </div>
            </template>
        </el-table-column>
<!--        <el-table-column label="价格" prop="cost" width="100">-->
<!--            <template #default="scope">-->
<!--                <el-select v-model="scope.row.cost" style="width: 80px" @change="update(scope.row)">-->
<!--                    <el-option :value="0"></el-option>-->
<!--                    <el-option :value="20"></el-option>-->
<!--                </el-select>-->
<!--            </template>-->
<!--        </el-table-column>-->
        <el-table-column label="上线时间" prop="online_date" width="140">
            <template #default="scope">
                <el-date-picker style="width: 120px" value-format="YYYY-MM-DD" v-model="scope.row.online_date"
                                @change="update(scope.row)" :clearable="false"></el-date-picker>
            </template>
        </el-table-column>
        <el-table-column label="系列" prop="series" width="150">
          <template #default="scope">
            <el-tag :disabled="loading" v-if="scope.row.series" v-for="series in scope.row.series">{{ series }}</el-tag>
          </template>
        </el-table-column>
<!--        <el-table-column label="分类" prop="categories" width="80">-->
<!--            <template #default="scope">-->
<!--                <el-tag v-for="c in scope.row.categories">{{ category_map[c] }}</el-tag>-->
<!--            </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column label="主要标签" prop="major_tags" width="100">-->
<!--            <template #default="scope">-->
<!--                <el-tag v-for="t in scope.row.major_tags">{{ major_map[t] }}</el-tag>-->
<!--            </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column label="次要标签" prop="minor_tags" width="100">-->
<!--            <template #default="scope">-->
<!--                <el-tag v-for="t in scope.row.minor_tags">{{ minor_map[t] }}</el-tag>-->
<!--            </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column label="辅助标签" prop="assistant_tags" width="100">-->
<!--            <template #default="scope">-->
<!--                <el-tag v-for="t in scope.row.assistant_tags">{{ assistant_map[t] }}</el-tag>-->
<!--            </template>-->
<!--        </el-table-column>-->
    </el-table>
    <div style="display: flex; justify-content: space-between">
        <el-pagination layout="prev, pager, next" :total="data.length" :page-size="size"
                       v-model:current-page="page" :disabled="loading"></el-pagination>
    </div>
    <el-dialog v-model="dialog_opened" :before-close="reset" width="80%">
        <el-select v-model="country" filterable :disabled="loading" clearable placeholder="全球" style="width: 140px;">
            <el-option v-for="c in $root.country_list" :value="c.code"
                       :label="c.en_name + ' (' + c.code + ')'"></el-option>
        </el-select>
        <el-select v-model="platform" filterable placeholder="请选择平台" :disabled="loading" style="width: 140px; margin-left: 10px">
          <el-option v-for="c in ['android', 'ios']" :value="c" :label="c"></el-option>
        </el-select>
        <el-select v-model="series" filterable placeholder="请选择系列" :disabled="loading" style="width: 140px; margin-left: 10px">
          <el-option v-for="c in series_list" :value="c.key" :label="c.key"></el-option>
        </el-select>
        <vue-draggable-next v-model="imageList" :animation="300" handle=".handle">
            <div v-for="l in imageList" style="margin: 5px;position: relative;display: inline-block"
                 :class="l.include_country && l.include_country.length && !l.include_country.includes(country) || l.exclude_country && l.exclude_country.includes(country)?'':'handle'">

                <el-image :src="`${$CDN_URL}/${l.thumbnail}`"
                          style="width: 200px;cursor: pointer;"></el-image>
                <div class="cover"
                     v-if="l.include_country && l.include_country.length &&  !l.include_country.includes(country) || l.exclude_country && l.exclude_country.includes(country)
                            || l.platform && l.platform !== platform"></div>
                <el-icon v-if="l.cost> 0" style="position: absolute; top: 0; left: 3px;" :size="25" :color="'white'">
                  <VideoCamera />
                </el-icon>
            </div>
        </vue-draggable-next>
        <template #footer>
            <el-button @click="reset" :loading="loading">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {timestampToDate, update} from "../libs/utils";
import {VueDraggableNext} from 'vue-draggable-next'

export default {
    name: "Update",
    components: {VueDraggableNext},
    data() {
        return {
            data_map: {}, loading: false, dialog_opened: false, editing: null, list: [], page: 1, size: 20,
            platform: 'android', data: [], country: null, series_list: [], series: null,
            series_map: {}
            // category_list: [], major_list: [], minor_list: [], assistant_list: [],
            // category_map: {}, major_map: {}, minor_map: {}, assistant_map: {},
        }
    },
    methods: {
        init() {
            this.loading = true;
            const now = Date.now();
            axios.get(`/cms/v1/image`, {
                params: {
                    module: 'LIBRARY', limit: 10000, status: 'AUDITED'
                }
            }).then(res => {
                this.data = [];
                res.data.data.imageList.forEach(image => {
                    if (image.online_date) {
                        if (this.data_map[image.online_date]) {
                            this.data_map[image.online_date].push(image);
                        } else {
                            this.data_map[image.online_date] = [image];
                        }
                    }
                    if (!image.online_date || image.online_date > timestampToDate(now)) {
                        this.data.push(image);
                    }
                });
                this.data.sort(function (a, b) {
                    if (a.online_date && b.online_date) {
                        return a.online_date < b.online_date ? 1 : -1;
                    } else if (!a.online_date) {
                        return 1;
                    } else {
                        return -1
                    }
                });
                for (let key in this.data_map) {
                    this.data_map[key].sort(function (a, b) {
                        return a.sort - b.sort;
                    })
                }
                this.loading = false;
            });
        },
        openDialog(selected) {
            if (selected) {
                this.dialog_opened = true;
                this.series_map = {}
                this.editing = selected;
                update(this.list, selected);
                this.list = this.list.sort((a, b) => (a?.sort ?? 0) - (b?.sort ?? 0));
            }
        },
        reload() {
            this.data_map = {};
            this.data.forEach(image => {
                if (image.online_date && image.status === 'AUDITED') {
                    if (this.data_map[image.online_date]) {
                        this.data_map[image.online_date].push(image);
                    } else {
                        this.data_map[image.online_date] = [image];
                    }
                }
            })
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/image/${row.id}`, row).then(_ => {
                this.reload();
                this.loading = false;
            })
        },
        df(map, list) {
            list.forEach(l => {
                map[l.id] = l.name;
                if (l.children.length) {
                    this.df(map, l.children);
                }
            });
        },
        reset() {
            this.dialog_opened = this.loading = false;
            this.editing = this.country = null;
        },
        submit() {
            this.loading = true;
            const sort = [];
            this.imageList.forEach((d, i) => {
              sort.push(d.id)
              d.sort = i
            });
            axios.post(`/cms/v1/sort/image`, {sort}).then(_ => {
                update(this.editing, this.list);
                this.reset();
            });
        },
        resort() {
        },
    },
    mounted() {
        this.loading = true;
        axios.all([
        //     axios.get(`/cms/v1/category`).then(res => {
        //         this.category_list = res.data.data.categoryList;
        //         this.category_list.forEach(c => {
        //             this.category_map[c.id] = c.i18n_name.ZH_HANS;
        //         })
        //     }),
        //     axios.get('/cms/v1/tag/major').then(res => {
        //         this.major_list = res.data.data.tagList;
        //         this.df(this.major_map, this.major_list);
        //     }),
        //     axios.get('/cms/v1/tag/minor').then(res => {
        //         this.minor_list = res.data.data.tagList;
        //         this.df(this.minor_map, this.minor_list);
        //     }),
        //     axios.get('/cms/v1/tag/assistant').then(res => {
        //         this.assistant_list = res.data.data.tagList;
        //         this.df(this.assistant_map, this.assistant_list);
        //     }),
          axios.get(`/cms/v1/series`).then(res => {
            this.series_list = res.data.data.seriesList;
            if(this.series_list.length > 0) {
              this.series = this.series_list[0].key
            }
          })
        ]).then(_ => {
            this.init();
        })
    },
    computed: {
        dataPage() {
            return this.data.slice((this.page - 1) * this.size, this.page * this.size);
        },
        imageList: {
          get() {
            if (!this.series) return this.list;
            if (!this.series_map[this.series])
              this.series_map[this.series] = this.list.filter(item => item.series && item.series.includes(this.series));
            return this.series_map[this.series]
          },
          set(value) {
            if (!this.series) this.list = value
            this.series_map[this.series] = value
          }
        }
    }
}
</script>

<style scoped>
.cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: not-allowed;
    background-color: #FFF;
    opacity: 0.75;
}
</style>