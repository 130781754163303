<template>
    <el-dialog v-model="dialog_opened" width="80%" :title="collection?collection.i18n_name.ZH_HANS:''">
        <h4>共{{ data.length }}张</h4>
        <el-table :data="data" v-loading="loading">
            <el-table-column label="ID" prop="image_id" width="150"></el-table-column>
            <el-table-column label="状态" prop="status" width="100">
                <template #default="scope">
                    <div style="color: var(--el-color-success)" v-if="scope.row.status==='AUDITED'">
                        <el-icon>
                            <CircleCheckFilled/>
                        </el-icon>
                        通过
                    </div>
                    <div style="color: var(--el-color-warning)" v-else-if="scope.row.status==='AUDITING'">
                        <el-icon>
                            <Loading/>
                        </el-icon>
                        审核中
                    </div>
                    <div style="color: var(--el-color-error)" v-if="scope.row.status==='REJECTED'">
                        <el-icon>
                            <CircleCloseFilled/>
                        </el-icon>
                        不通过
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="图片" prop="resource" width="200">
                <template #default="scope">
                    <el-image lazy :src="`${$CDN_URL}/${scope.row.thumbnail}`" style="width: 180px"/>
                </template>
            </el-table-column>
            <el-table-column label="第几关解锁" prop="unlock_level" width="150">
              <template #default="scope">
                <el-input v-model="scope.row.unlock_level" :disabled="loading" style="margin-left:10px;margin-right:10px;width: 60px"
                          @change="setUnlock(scope.row)"></el-input>
              </template>
            </el-table-column>
<!--            <el-table-column label="来源" prop="source" width="100"></el-table-column>-->
<!--            <el-table-column label="主要标签" prop="major_tags" width="100">-->
<!--                <template #default="scope">-->
<!--                    <el-tag v-for="t in scope.row.major_tags">-->
<!--                        {{ major_map[t] }}-->
<!--                    </el-tag>-->
<!--                </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column label="次要标签" prop="minor_tags" width="100">-->
<!--                <template #default="scope">-->
<!--                    <el-tag v-for="t in scope.row.minor_tags">-->
<!--                        {{ minor_map[t] }}-->
<!--                    </el-tag>-->
<!--                </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column label="辅助标签" prop="assistant_tags" width="100">-->
<!--                <template #default="scope">-->
<!--                    <el-tag v-for="t in scope.row.assistant_tags">-->
<!--                        {{ assistant_map[t] }}-->
<!--                    </el-tag>-->
<!--                </template>-->
<!--            </el-table-column>-->
            <el-table-column label="操作">
                <template #default="scope">
<!--                    <el-button type="success" circle icon="Top" :loading="loading" size="small"-->
<!--                               :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>-->
<!--                    <el-button type="success" circle icon="Bottom" :loading="loading" size="small"-->
<!--                               :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>-->
<!--                    <el-input v-model="scope.row.sort" :disabled="loading" style="margin-left:10px;margin-right:10px;width: 60px"-->
<!--                              @change="changeSort(scope.row)"></el-input>-->
                    <el-button type="danger" :disabled="loading" @click="remove(scope.row)">从集合中删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-table :data="image_list" style="margin-top: 10px">
          <el-table-column label="ID" prop="id" width="150"></el-table-column>
          <el-table-column label="Collection" prop="collection" width="150"></el-table-column>
          <el-table-column label="第几关解锁" prop="unlock_level" width="150">
            <template #default="scope">
              <el-input v-model="scope.row.unlock_level" :disabled="loading" style="margin-left:10px;margin-right:10px;width: 60px"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="status" width="150">
            <template #default="scope">
              <el-select :disabled="loading" style="width: 100px" v-model="scope.row.status"
                         @change="update(scope.row)">
                <el-option label="通过" value="AUDITED"></el-option>
                <el-option label="审核中" value="AUDITING"></el-option>
                <el-option label="不通过" value="REJECTED"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="图片" prop="resource" width="220">
            <template #default="scope">
              <el-image lazy :src="`${$CDN_URL}/${scope.row.origin}`" style="width: 200px"/>
            </template>
          </el-table-column>
          <el-table-column label="上线时间" prop="online_date" width="200">
            <template #default="scope">
              <el-date-picker style="width: 120px" value-format="YYYY-MM-DD" v-model="scope.row.online_date"
                              @change="update(scope.row)" :clearable="false"></el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template #default="scope">
              <el-button type="primary" :disabled="scope.row.added"
                         @click="addToCollection(scope.row, scope.row.id)">
                {{ scope.row.added ? '已添加' : '添加到合集' }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 20px">
            <el-button type="primary" :loading="loading" @click="createImage">向集合中添加图片</el-button>
        </div>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {ElMessageBox, ElMessage} from 'element-plus'

export default {
    name: "CollectionImage",
    data() {
        return {
            loading: false, dialog_opened: false, data: [], collection: null, image_list: [],
        }
    },
    inject: ['major_map', 'minor_map', 'assistant_map'],
    methods: {
        init(collection) {
            this.collection = collection;
            this.$nextTick(_ => {
                this.dialog_opened = this.loading = true;
                axios.get(`/cms/v1/collection/${collection.id}/image`).then(res => {
                    this.data = res.data.data.imageList;
                    this.loading = false;
                })
                axios.get(`/cms/v1/image?module=COLLECTION`).then(res => {
                  this.image_list = res.data.data.imageList;
                })
            })
        },
        remove(row) {
            ElMessageBox.confirm('确定要从集合中删除该图片吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/collection/${this.collection.id}/image`, {data: {image_id: row.image_id}}).then(res => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            }).catch(_ => {
            })
        },
        createImage() {
            ElMessageBox.prompt('请输入图片id', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                inputPattern: /[a-z0-9]{24}/,
                inputErrorMessage: '请输入合法id',
            }).then(({value}) => {
              this.addToCollection(null, value)
            }).catch(_ => {})
        },
        addToCollection(image, id) {
          if (!image.unlock_level) {
            alert("请设置第几关解锁")
            return
          }
          this.loading = true;
          axios.post(`/cms/v1/collection/${this.collection.id}/image`, {image_id: id, unlock_level: image.unlock_level}).then(res => {
            if(image) {
              image.added = true
            }
            this.data.push(res.data.data);
            this.resort();
            this.loading = false;
          }).catch(err => {
            ElMessage.error(err.response.data.status.message);
            this.loading = false;
          })
        },
        update(row) {
          this.loading = true;
          axios.put(`/cms/v1/image/${row.id}`, row).then(_ => {
            this.loading = false;
          })
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.resort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.resort();
        },
        changeSort(row) {
          const sort = row.sort;
          const index = this.data.indexOf(row);
          if (index > -1 && sort >= 0 && sort < this.data.length) {
            this.data.splice(index, 1);
            this.data.splice(sort, 0, row);
            this.resort();
          } else {
            alert("invalid index")
          }
        },
        setUnlock(row) {
          this.loading = true
          axios.put(`/cms/v1/collection/${this.collection.id}/image`, {image_id: row.image_id, unlock_level: row.unlock_level}).then(_ => {
            this.loading = false
          })
        },
        resort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            })
            axios.post(`/cms/v1/sort/collection/image`, {sort}).then(_ => {
            })
        },
    },
}
</script>

<style scoped>

</style>