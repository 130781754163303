<template>
    <el-table style="margin-top: 20px;" v-loading="loading" :data="data">
        <el-table-column label="ID" prop="id" width="150"></el-table-column>
        <el-table-column label="状态" prop="status" width="150">
            <template #default="scope">
                <el-select :disabled="loading" style="width: 100px" v-model="scope.row.status"
                           @change="update(scope.row)">
                    <el-option label="通过" value="AUDITED"></el-option>
                    <el-option label="审核中" value="AUDITING"></el-option>
                    <el-option label="不通过" value="REJECTED"></el-option>
                </el-select>
            </template>
        </el-table-column>
        <el-table-column label="图片" width="220">
            <template #default="scope">
                <el-image lazy :src="`${$CDN_URL}/${scope.row.origin}`" style="width: 200px"/>
            </template>
        </el-table-column>
        <el-table-column label="上线时间" prop="online_date" width="200">
            <template #default="scope">
                <el-date-picker style="width: 120px" value-format="YYYY-MM-DD" v-model="scope.row.online_date"
                                @change="update(scope.row)" :clearable="false"></el-date-picker>
            </template>
        </el-table-column>
<!--        <el-table-column label="版权状态" width="150">-->
<!--          <template #default="scope">-->
<!--            <el-tag v-if="scope.row.unauthorized" type="danger">无版权</el-tag>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column label="操作" width="300">
            <template #default="scope">
                <el-button type="success" circle icon="Top" :loading="loading" size="small"
                           :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                <el-button type="success" circle icon="Bottom" :loading="loading" size="small"
                           :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
                <el-input v-model="scope.row.sort" :disabled="loading" style="margin-left:10px; width: 60px"
                          @change="changeSort(scope.row)"></el-input>
            </template>
        </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: space-between">
        <el-pagination layout="prev, pager, next" :total="total" :page-size="this.query.limit"
                       @current-change="pageChange" :disabled="loading"></el-pagination>
        <el-select v-model="query.limit" style="width: 100px" @change="reload" :disabled="loading">
            <el-option :value="20"></el-option>
            <el-option :value="50"></el-option>
            <el-option :value="100"></el-option>
        </el-select>
    </div>

</template>

<script>
import axios from "ts-axios-new";
import DateRangePicker from "../base/DateRangePicker";
import {update} from "../libs/utils";
import {ElMessage, ElMessageBox} from 'element-plus'

export default {
    name: "Index",
    components: {DateRangePicker},
    data() {
        return {
            loading: false, data: [], total: 0, page: 1,
            query: {
                id: null,
                online: null,
                status: null,
                series: null,
                // module: null,
                limit: 200,
                skip: 0,
                start: null,
                end: null,
                unauthorized: null
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/challenge`, {params: this.query}).then(res => {
                this.data = res.data.data.imageList;
                this.total = res.data.data.total;
                this.loading = false;
            });
        },
        reload() {
            if (!this.query.id || this.query.id.length === 24) {
                this.skip = 0;
                this.page = 1;
                this.init();
            }
        },
        df(map, list) {
            list.forEach(l => {
                map[l.id] = l.name;
                if (l.children.length) {
                    this.df(map, l.children);
                }
            });
        },
        pageChange(page) {
            this.query.skip = (page - 1) * this.query.limit;
            this.init();
        },
        moveUp(index) {
          const row = this.data.splice(index, 1);
          this.data.splice(index - 1, 0, row[0]);
          this.resort();
        },
        moveDown(index) {
          const row = this.data.splice(index, 1);
          this.data.splice(index + 1, 0, row[0]);
          this.resort();
        },
        changeSort(row) {
          const sort = row.sort;
          const index = this.data.indexOf(row);
          if (index > -1 && sort >= 0 && sort < this.data.length) {
            this.data.splice(index, 1);
            this.data.splice(sort, 0, row);
            this.resort();
          } else {
            alert("invalid index")
          }
        },
        resort() {
          const sort = [];
          this.data.forEach(d => {
            sort.push(d.id);
            d.sort = sort.length - 1
          })
          axios.post(`/cms/v1/sort/challenge`, {sort}).then(_ => {
          })
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/image/${row.id}`, row).then(res => {
                this.loading = false;
            })
        },
    },
    mounted() {
        this.init();
    },
}
</script>

<style scoped>

</style>