import Collection from './components/Collection.vue';
import Update from './components/Update.vue';
import Daily from './components/Daily.vue';
import Suspend from './components/Suspend.vue';
import Popup from './components/Popup.vue';
import Score from './components/Score.vue';
import Metric from './components/Metric.vue';
import CountryHot from './components/CountryHot.vue';
import CountryRookie from './components/CountryRookie.vue';
import CountryManual from './components/CountryManual.vue';
import IAP from './components/IAP.vue';
import Subscription from './components/Subscription.vue';
import NewbieSales from "./components/NewbieSales.vue";

import Series from "./components/Series.vue";
import Image from "./components/Image.vue";
import SeriesImage from "./components/SeriesImage.vue";
import ImageJigsaw from "./components/ImageJigsaw.vue";
import Challenge from "./components/Challenge.vue";
import TranssionImage from "./components/transsion/TranssionImage.vue";
import TranssionRookie from "./components/transsion/TranssionRookie.vue";


export const routes = [
    {
        path: '/',
        redirect: '/image' // 默认重定向到 '/image'
    },
    {
        name: 'Image',
        path: '/image',
        component: Image,
    }, {
        name: 'Series',
        path: '/series',
        component: Series,
    }, {
        name: 'SeriesImage',
        path: '/series-image',
        component: SeriesImage,
    }, {
        name: 'ImageJigsaw',
        path: '/image-jigsaw',
        component: ImageJigsaw,
    }, {
        name: 'Collection',
        path: '/collection',
        component: Collection,
    }, {
        name: 'Challenge',
        path: '/challenge',
        component: Challenge,
    }, {
        name: 'Update',
        path: '/update',
        component: Update,
    }, {
        name: 'Daily',
        path: '/daily',
        component: Daily,
    }, {
        name: 'Popup',
        path: '/popup',
        component: Popup
    }, {
        name: 'Suspend',
        path: '/suspend',
        component: Suspend,

    }, {
        name: 'Score',
        path: '/score',
        component: Score,
    }, {
        name: 'Metric',
        path: '/metric',
        component: Metric,
    }, {
        name: 'CountryHot',
        path: '/country/hot',
        component: CountryHot,
    }, {
        name: 'CountryRookie',
        path: '/country/rookie',
        component: CountryRookie,
    }, {
        name: 'CountryManual',
        path: '/country/manual',
        component: CountryManual,
    }, {
        name: 'IAP',
        path: '/iap',
        component: IAP,
    }, {
        name: 'Subscription',
        path: '/subscription',
        component: Subscription,
    }, {
        name: 'NewbieSales',
        path: '/newbie-sales',
        component: NewbieSales,
    }, {
        name: 'Daily',
        path: '/daily',
        component: Daily,
    }, {
        name: 'TranssionImage',
        path: '/transsion-image',
        component: TranssionImage,
    }, {
        name: 'TranssionRookie',
        path: '/transsion-rookie',
        component: TranssionRookie,
    }
]